<template>
  <div>
    <b-card title="Access Rights - Cloud users">
      <blueprint-data v-if="cloudUsersPath" :api-route-path="cloudUsersPath" :fields="cloudUsersFields" no-edit>
        <template v-slot:verified="{ item }">
          <b-badge v-if="item.verified" variant="success" style="margin-right:.2rem">
            yes
          </b-badge>
          <b-badge v-if="!item.verified" variant="danger" style="margin-right:.2rem">
            no
          </b-badge>
          <br>
          <a v-if="!item.verified" href="#" @click.prevent="resendInvitation(item)">Resend Invitation</a>
        </template>
        <template v-slot:default="{ state, item }">
          <b-form-group label="Firstname" label-for="firstname">
            <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
          </b-form-group>
          <b-form-group label="Lastname" label-for="lastname">
            <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
          </b-form-group>
          <b-form-group v-if="usernameAttribute=='USERNAME'" label="Username" label-for="username">
            <b-form-input id="name" v-model="item.username" :state="state('username')" type="text" placeholder="Username" />
          </b-form-group>
          <b-form-group label="E-mail" label-for="email" :description="usernameAttribute=='EMAIL'?'E-mail address is used as username': ''">
            <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
          </b-form-group>
          <b-form-group label="Phone" label-for="phone" :description="usernameAttribute=='PHONE'?'Phone number is used as username': ''">
            <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
          </b-form-group>
        </template>
      </blueprint-data>
    </b-card>
    <br>
    <b-card v-if="accessLocalUsers" title="Access Rights - Local users">
      <blueprint-data v-if="localUsersPath" :api-route-path="localUsersPath" :fields="localFields">
        <template v-slot:actions="{ item }">
          <BookingAclSmsButton v-if="item.phone" :booking-id="bookingId" :local-user-id="item.id" />
          <BookingAclEmailButton v-if="item.email" :booking-id="bookingId" :local-user-id="item.id" />
        </template>
        <template v-slot:default="{ state, item }">
          <b-form-group label="Firstname" label-for="firstname">
            <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
          </b-form-group>
          <b-form-group label="Lastname" label-for="lastname">
            <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
          </b-form-group>
          <b-form-group label="E-mail" label-for="email">
            <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
          </b-form-group>
          <b-form-group label="Phone" label-for="phone">
            <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
          </b-form-group>
          <b-form-group v-if="!item.id" label="Pin" label-for="pin">
            <b-input-group>
              <b-form-input id="pin" v-model="item.pin" :state="state('pin')" :type="showPin ? 'text' : 'password'" placeholder="User pin code" />
              <b-input-group-append>
                <b-button @click.prevent="" @mousedown.prevent="showPin = true" @mouseup.prevent="showPin = false">
                  <fa-icon icon="eye" />
                </b-button>
                <b-button @click.prevent="item.pin = generatePin(); showPin = true; setTimeout(() => { showPin = false; }, 500);">
                  <fa-icon icon="cogs" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </template>
      </blueprint-data>
    </b-card>
    <br>
    <b-card v-if="accessLocalCards" title="Access Rights - Access Cards">
      <blueprint-data v-if="accessCardsPath" :api-route-path="accessCardsPath" :fields="localFields">
        <template v-slot:actions="{ item }">
          <BookingAclSmsButton v-if="item.phone" :booking-id="bookingId" :card-id="item.id" />
          <BookingAclEmailButton v-if="item.email" :booking-id="bookingId" :card-id="item.id" />
        </template>
        <template v-slot:default="{ state, item }">
          <b-form-group label="Firstname" label-for="firstname">
            <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
          </b-form-group>
          <b-form-group label="Lastname" label-for="lastname">
            <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
          </b-form-group>
          <b-form-group label="E-mail" label-for="email">
            <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
          </b-form-group>
          <b-form-group label="Phone" label-for="phone">
            <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
          </b-form-group>
          <b-form-group v-if="!item.id" label="Card" label-for="card">
            <b-form-input id="card" v-model="item.card" :state="state('card')" type="text" placeholder="Card number" />
          </b-form-group>
          <b-form-group v-if="!item.id" label="Pin" label-for="pin">
            <b-input-group>
              <b-form-input id="pin" v-model="item.pin" :state="state('pin')" :type="showPin ? 'text' : 'password'" placeholder="Card pin code (optional)" />
              <b-input-group-append>
                <b-button @click.prevent="" @mousedown.prevent="showPin = true" @mouseup.prevent="showPin = false">
                  <fa-icon icon="eye" />
                </b-button>
                <b-button @click.prevent="item.pin = generatePin(); showPin = true; setTimeout(() => { showPin = false; }, 500);">
                  <fa-icon icon="cogs" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </template>
      </blueprint-data>
    </b-card>
    <br>
    <b-card title="Access Rights - Lisence Plates">
      <blueprint-data v-if="accessCardsPath" :api-route-path="platesPath" :fields="platesFields">
        <template v-slot:default="{ state, item }">
          <b-form-group label="Firstname" label-for="firstname">
            <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
          </b-form-group>
          <b-form-group label="Lastname" label-for="lastname">
            <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
          </b-form-group>
          <b-form-group label="E-mail" label-for="email">
            <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
          </b-form-group>
          <b-form-group label="Phone" label-for="phone">
            <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
          </b-form-group>
          <b-form-group label="Plate" label-for="plate">
            <b-form-input id="plate" v-model="item.plate" :state="state('plate')" type="text" placeholder="Plate" />
          </b-form-group>
        </template>
      </blueprint-data>
    </b-card>
  </div>
</template>


<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs, faEye,
} from '@fortawesome/free-solid-svg-icons';

import BookingAclSmsButton from '../../../service-modules/booking_notifications/components/booking-acl-sms-button.vue';
import BookingAclEmailButton from '../../../service-modules/booking_notifications/components/booking-acl-email-button.vue';

library.add(faCogs, faEye);

const sharedFields = [
  {
    key: 'firstname',
    sortable: true,
  },
  {
    key: 'lastname',
    sortable: true,
  },
  {
    key: 'email',
    sortable: true,
  },
  {
    key: 'phone',
    sortable: true,
  },
];

export default {
  components:{
    BookingAclSmsButton,
    BookingAclEmailButton
  },
  props: ['bookingId'],
  data () {
    return {
      accessLocalCards: false,
      accessLocalUsers: false,
      showPin: false,
      cloudUsersPath: null,
      localUsersPath: null,
      accessCardsPath: null,
      cloudUsersFields: [
        ...sharedFields,
        {
          key: 'verified',
          sortable: true,
          slot: true,
        },
      ],
      localFields: [
        ...sharedFields,
      ],
      platesFields: [
        ...sharedFields,
        {
          key: 'plate',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    usernameAttribute () {
      return this.$store.getters.org ? this.$store.getters.org.cognitoUsernameAttribute : 'USERNAME';
    },
  },
  mounted () {
    if (!this.bookingId) {
      throw new Error('Booking id missing for booking acl component.');
    }
    const id = this.bookingId;
    this.cloudUsersPath = `bookings/${id}/users`;
    this.localUsersPath = `bookings/${id}/localusers`;
    this.accessCardsPath = `bookings/${id}/cards`;
    this.platesPath = `bookings/${id}/plates`;
    this.$http.get(`bookings/${id}`)
      .then(({ data }) => {
        if (data.unit && data.unit.type) {
          const type = data.unit.type;
          this.accessLocalCards = type.accessLocalCards;
          this.accessLocalUsers = type.accessLocalUsers;
        }
      })
      .catch(err => this.err(err));
  },
  methods: {
    setTimeout (...args) {
      return window.setTimeout(...args);
    },
    generatePin () {
      return Math.floor(Math.random() * (999999 - 100000 + 1) + 100000).toString();
    },
    resendInvitation (user) {
      const { id } = user;
      this.$http.post(`users/${id}/invitation`, {})
        .then(() => this.success('Done', 'Invitation sent'))
        .catch(this.err);
    },
    success (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
  },
};
</script>
