<template>
  <span v-if="modules && !!modules['booking_notifications']">
    <a href="#" @click.prevent="sendSms">
      <fa-icon icon="comment-dots" />Send code via SMS
    </a>
    <b-modal ref="smsmodal" size="md" no-enforce-focus hide-header>
      <b-form-group label="">
        <b-form-radio v-model="scheduled" :value="null">Send now</b-form-radio>
        <b-form-radio v-if="daybeforeBooking" v-model="scheduled" :value="daybeforeBooking">Scedule day before booking starts</b-form-radio>
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="send()">
          <span v-if="!scheduled">Send</span>
          <span v-if="scheduled">Schedule</span>
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

library.add(faCommentDots);

export default {
  props: ['bookingId', 'localUserId', 'cardId'],
  data () {
    return {
      scheduled: null,
      daybeforeBooking: null,
      errors: {},
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    sendSms () {
      if (!this.bookingId) {
        return this.err(new Error('Invalid booking'));
      }
      this.$http.get(`bookings/${this.bookingId}`)
        .then((res) => { // eslint-disable-line
          const booking = res.data;
          if (booking.range && booking.range.start) {
            const date = new Date(booking.range.start);
            const now = new Date();
            if (now <= date) {
              date.setDate(date.getDate() - 1);
              this.daybeforeBooking = date;
            }
          }
          this.$refs.smsmodal.show();
        })
        .catch(this.err);
    },
    send () {
      const url = this.localUserId ? `bookings/${this.bookingId}/localusers/${this.localUserId}/sms` : `bookings/${this.bookingId}/cards/${this.cardId}/sms`;
      const body = this.scheduled ? { scheduled: this.scheduled } : {};
      this.$http.post(url, body)
        .then(() => {
          // success
          this.$refs.smsmodal.hide();
          this.$notify({ text: this.scheduled ? 'SMS scheduled.' : 'SMS sent.' });
        })
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },

  },
};
</script>
